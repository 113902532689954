import { Chain } from 'viem';

export const rhodolite = {
  id: 17042,
  network: 'rhodolite',
  name: 'Rhodolite',
  nativeCurrency: { name: 'Rhodolite Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://rpc.rhodolitechain.com/'],
    },
    public: {
      http: ['https://rpc.rhodolitechain.com/'],
    },
  },
  testnet: true,
} as const satisfies Chain;

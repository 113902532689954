import { Chain } from 'viem';
import { mainnet, holesky, sepolia } from 'viem/chains';
import { redstone } from './redstone';
import { garnetHolesky } from './garnetHolesky';
import { redstoneHolesky } from './redstoneHolesky';
import { rhodolite } from './rhodolite';
import { pyrope } from './pyrope';
export { redstone } from './redstone';
export { garnetHolesky } from './garnetHolesky';
export { redstoneHolesky } from './redstoneHolesky';
export { rhodolite } from './rhodolite';
export { pyrope } from './pyrope';

export const CHAIN_ID_TO_CHAIN: Record<number, Chain> = {
  [mainnet.id]: mainnet,
  [redstone.id]: redstone,
  [holesky.id]: holesky,
  [sepolia.id]: sepolia,
  [redstoneHolesky.id]: redstoneHolesky,
  [garnetHolesky.id]: garnetHolesky,
  [rhodolite.id]: rhodolite,
  [pyrope.id]: pyrope,
};

const chainBaseUrls: Record<number, string> = {
  [redstone.id]: 'https://race.redstone.xyz',
  [garnetHolesky.id]: 'https://garnetchain.com',
  [rhodolite.id]: 'http://rhodolitechain.com',
  [redstoneHolesky.id]: 'https://deprecated-holesky.redstone.xyz/',
  [pyrope.id]: 'https://pyropechain.com',
};

export function pathToChainUrl(targetChainId: number): string {
  const baseUrl = chainBaseUrls[targetChainId];
  if (location.pathname.startsWith('/data-availability')) {
    return `${baseUrl}/data-availability`;
  }
  if (location.pathname.startsWith('/deposit')) {
    return `${baseUrl}/deposit`;
  }
  if (location.pathname.startsWith('/withdraw')) {
    return `${baseUrl}/withdraw`;
  }
  if (
    location.pathname === '/docs/what-is-redstone' ||
    location.pathname === '/docs/network-info' ||
    location.pathname === '/docs/contract-addresses'
  ) {
    return `${baseUrl}${location.pathname}`;
  }
  return `${baseUrl}/deposit`;
}

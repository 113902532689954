import { Chain } from 'viem';

export const pyrope = {
  id: 695569,
  network: 'pyrope',
  name: 'Pyrope',
  nativeCurrency: { name: 'Pyrope Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://rpc.pyropechain.com/'],
    },
    public: {
      http: ['https://rpc.pyropechain.com/'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Blockscout',
      url: 'https://explorer.pyropechain.com/',
    },
  },
  testnet: true,
} as const satisfies Chain;

'use client';

import { Logo } from '../components/Logo/Logo';
import { redstone } from '../chains/redstone';
import {
  garnetHolesky,
  pathToChainUrl,
  redstoneHolesky,
  rhodolite,
  pyrope,
} from '../chains/chains';
import { GarnetLogo } from '../components/Logo/GarnetLogo';
import { RhodoliteLogo } from '../components/Logo/RhodoliteLogo';
import { PyropeLogo } from '../components/Logo/PyropeLogo';
import { twMerge } from 'tailwind-merge';
import { ArrowDownIcon } from './icons/ArrowDownIcon';
import { activeChainId } from '@/common';

export function NavChainSelectItem({
  chainId,
  active = false,
  open = false,
}: {
  chainId: number;
  active?: boolean;
  open?: boolean;
}) {
  const mainSection = (
    <div
      className={twMerge(
        'inline-flex h-full w-full items-center gap-[13px] lg:w-[225px]',
        activeChainId === redstoneHolesky.id && 'lg:w-[330px]',
        active ? 'justify-between' : 'flex-start',
        'gap-[13px] px-5 py-2 pr-6',
        'text-sm font-semibold leading-none text-white',
        active && 'md:border-r md:border-r-white/20 md:pr-5',
        !active && 'border-t border-t-white/10 py-4',
        chainId === redstone.id && active && 'bg-redstone hover:bg-redstone-dark',
        (chainId === rhodolite.id || chainId === redstoneHolesky.id) &&
          active &&
          'bg-rhodolite hover:bg-rhodolite-dark',
        chainId === garnetHolesky.id && active && 'bg-garnet-holesky hover:bg-garnet-holesky-dark',
        chainId === pyrope.id && active && 'bg-pyrope hover:bg-pyrope-dark',
        chainId === redstone.id && open && 'bg-redstone-dark',
        (chainId === rhodolite.id || chainId === redstoneHolesky.id) && open && 'bg-rhodolite-dark',
        chainId === garnetHolesky.id && open && 'bg-garnet-holesky-dark',
      )}
    >
      <div className="flex gap-[13px]">
        <div className="flex-shrink-0">
          {chainId === redstone.id && <Logo width="40" height="40" />}
          {chainId === redstoneHolesky.id && <Logo width="40" height="40" />}
          {chainId === garnetHolesky.id && <GarnetLogo width="40" height="40" />}
          {chainId === rhodolite.id && <RhodoliteLogo width="40" height="40" />}
          {chainId === pyrope.id && <PyropeLogo width="40" height="40" />}
        </div>

        <div className="text-left">
          <h2 className="text-[20px] font-normal uppercase">
            {chainId === redstone.id && 'Redstone'}
            {chainId === garnetHolesky.id && 'Garnet'}
            {chainId === rhodolite.id && 'Rhodolite'}
            {chainId === redstoneHolesky.id && 'Redstone Holesky'}
            {chainId === pyrope.id && 'Pyrope'}
          </h2>
          <p className="mt-[8px] text-[13px] font-normal uppercase opacity-50">
            {chainId === redstone.id && 'Mainnet'}
            {chainId === garnetHolesky.id && 'Testnet'}
            {chainId === rhodolite.id && 'Devnet'}
            {chainId === redstoneHolesky.id && 'Testnet'}
            {chainId === pyrope.id && 'Testnet'}
          </p>
        </div>
      </div>

      {active && (
        <div className="text-shrink-0">
          <ArrowDownIcon className="ml-4" />
        </div>
      )}
    </div>
  );

  if (active) {
    return mainSection;
  }

  return <a href={pathToChainUrl(chainId)}>{mainSection}</a>;
}
